import { Button, CardContent, Checkbox, TextField, Typography } from "@material-ui/core";
import React from "react";
import { Grid, Box, CardHeader } from "@material-ui/core";
import { leftArrow, bgImg} from "./assets";
import "./Forgot.css";

class OTPInputAuthWeb extends React.Component {
  render() {
    return (
      <>
      <Grid container spacing={4} >
        
        <Grid item xs={6}>
        <img src={bgImg} />
        </Grid>
        <Grid item xs={6}>
            
            <Grid item xs={8} style={{marginTop:300}}>
              <Box boxShadow={20} style={{ borderRadius:"20px", padding: "30px" }}>
                <CardHeader title="Confirm OTP" subheader="Enter the 6 digit code sent to email id shrxxxxxxx@gmail.com" />
                  <CardContent>
                      <Grid container spacing={4}>
                          <Grid item xs={8}>
                              <Grid container spacing={2}>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                              </Grid>

                          </Grid>
                      </Grid>
                    <Grid container spacing={4} style={{marginTop:6}}>
                      <Grid item xs={12}>
                          <div style={{ display:"flex" }}>
                          <Typography>Didn't get a code? </Typography> 
                          <Typography color="primary">Resend Code 
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" fullWidth size="large" color="primary" 
                        style={{borderRadius:50,height:"50px"}}>
                            Confirm</Button>

                      </Grid>
                      <Grid item xs={12}>
                          <Typography variant="h6" >Already have an account?</Typography>
                        <Typography variant="h4" color="primary" style={{paddingTop:10}}><img src={leftArrow} className="arrow-s" />  Sign in 
                          </Typography> 
                      
                      </Grid>
                      
                    </Grid>

                  </CardContent>
                  </Box>
            </Grid>
          </Grid>
        </Grid>
      
          
      </>
    );
  }
}
export default OTPInputAuthWeb