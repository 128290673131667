import {
  Button,
  CardContent,
  Checkbox,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";
import React from "react";
import { Grid, CardHeader } from "@material-ui/core";
import {
  imgAdvisor,
  imgCustomer,
  imgPasswordVisible,
  bgImg1,
  bgImg2,
  bgImg3,
  bgImg4,
  signinImg,
  imgPasswordInVisible,
} from "./assets";
import "./emailLogin.css";
import EmailAccountLoginController from "./EmailAccountLoginController";
import FinappHeader from "../../../components/src/FinappHeader.web";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
// import API_URL from '../../env'
import Input from "@material-ui/core/Input";
import { NavLink } from "react-router-dom";


export default class EmailAccountLoginBlockWeb extends EmailAccountLoginController {

  render() {
    return (
      <>
        <div>
          <FinappHeader />
          <Grid container style={{ backgroundColor: "#F2F2F2" }}>
            <Grid item xs={6}>
              <img src={this.state.imgId} />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={8} style={{ marginTop: 200 }}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item style={{ marginLeft: "50px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        // className={classes.button}
                        style={{
                          borderRadius: "20px",
                          textTransform: "none",
                          color: "black",
                          backgroundColor: "white",
                          border: "1px solid black",
                        }}
                        startIcon={
                          <span
                            style={{
                              backgroundColor: "#3f51b5",
                              paddingRight: "10px",
                              paddingLeft: "10px",
                              borderRadius: "10px",
                            }}
                          >
                            <img src={imgCustomer} style={{ width: "50px" }} />
                          </span>
                        }
                      >
                        <b style={{ padding: "10px", fontSize: "25px" }}>
                          Customer
                        </b>
                      </Button>
                    </Grid>

                    <Grid item style={{ marginLeft: "50px" }}>
                      <Button
                        variant="contained"
                        disabled
                        color="primary"
                        size="large"
                        // className={classes.button}
                        style={{
                          borderRadius: "20px",
                          textTransform: "none",
                          color: "black",
                          backgroundColor: "white",
                          border: "1px solid black",
                        }}
                        startIcon={
                          <span
                            style={{
                              backgroundColor: "#3f51b5",
                              paddingRight: "10px",
                              paddingLeft: "10px",
                              borderRadius: "10px",
                            }}
                          >
                            <img src={imgAdvisor} style={{ width: "50px" }} />
                          </span>
                        }
                      >
                        <b style={{ padding: "10px", fontSize: "25px" }}>
                          Advisor
                        </b>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {/* form Start */}
                <Grid
                  item
                  style={{
                    margin: "-50px",
                    marginTop: "-50px",
                    width: "820px",
                  }}
                >
                  <Box boxShadow={20} className="mainbox">
                    {/* <CardHeader
                     style={{color: "#70738D" , fontSize:"20px"}}
                      title="Sign in as Customer"
                      subheader="Sign in and start managing your account"
                    /> */}
                    <Typography style={{ color: "#70738D", fontSize: "30px" }}>
                      <b>Sign in as Customer</b>
                    </Typography>
                    <Typography style={{ color: "#70738D" }}>
                      Sign in and start managing your account
                    </Typography>
                    <CardContent>
                      <Typography variant="h6" style={{ color: "#70738D" }} >Username</Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={this.state.email}
                        onInput={(e: any) =>
                          this.setState({ email: e.target.value })
                        }
                        style={{ marginBottom: "15px" }}
                      />
                      <Typography variant="h6" style={{ color: "#70738D" }} >Password</Typography>

                      {/* <TextField
                                                    type="password"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={this.state.Password}
                                                    onInput={(e: any) =>
                                                        this.setState({ Password: e.target.value })
                                                    }
                                                />
                                                <img src={imgPasswordVisible} /> */}
                      <Input
                        id="outlined-full-width"
                        type={this.state.values ? "password" : "text"}
                        // eslint-disable-next-line react-native/no-inline-styles
                        value={this.state.password}
                        onInput={(e: any) =>
                          this.setState({ password: e.target.value })
                        }
                        style={{
                          border: "0.2px solid gray",
                          padding: "10px",
                          borderRadius: "4px",
                          outline: "0px",
                        }}
                        fullWidth
                        endAdornment={<img
                          src={this.state.values ? imgPasswordVisible : imgPasswordInVisible}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.setState({ values: !this.state.values })}
                        />}
                      />

                      <Grid container spacing={4} style={{ marginTop: 6 }}>
                        <Grid item xs={6}>
                          <Typography style={{ color: "#70738D" }} >
                            {" "}
                            <Checkbox defaultChecked /> Keep me logged in?
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <NavLink
                            exact
                            activeClassName="active_class"
                            className="forgot"
                            to="./ForgotPasswordWeb"
                            style={{ color: "blue", marginBottom: "30px" }}
                          >
                            Forgot Password?
                          </NavLink>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={5}>
                          <Button
                            variant="contained"
                            fullWidth
                            size="large"
                            color="primary"
                            style={{ borderRadius: 50, height: "50px" }}
                            onClick={this.signInEmail}
                          >
                            Sign in
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ display: "flex" }}>
                            <Typography
                              variant="h5"
                              style={{ marginBottom: "-65px", color: "#70738D", fontSize: "15px" }}
                            >
                              Don't have an account?
                            </Typography>
                            {/* <Button>
                              <Typography
                                variant="h4"
                                color="primary"
                                style={{ textTransform: "none" }}
                              >
                                {" "}
                                Sign up
                              </Typography>
                            </Button> */}
                            <NavLink
                              exact
                              activeClassName="active_class"
                              className="header"
                              to="./EmailAccountRegistrationWeb"
                              style={{ color: "blue" }}
                            >
                              Sign up
                            </NavLink>
                            <Button>
                              <img
                                src={signinImg}
                                style={{
                                  height: "15px",
                                  width: "25px",
                                  marginLeft: "-30px",
                                }}
                                className="signin"
                              />
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {/* </Card> */}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}
