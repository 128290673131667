import { Button, CardContent, Checkbox, TextField, Typography } from "@material-ui/core";
import React from "react";
import { Grid, Box, CardHeader } from "@material-ui/core";
import { leftArrow, bgImg} from "./assets";
import "./Forgot.css";

export default class ForgotPasswordOTPWeb extends React.Component {
  render() {
    return (
      <>
      <Grid container spacing={4} >
        
        <Grid item xs={6}>
          <img src={bgImg} />
        </Grid>
        <Grid item xs={6}>
            
            <Grid item xs={8} style={{marginTop:200}}>
              <Box boxShadow={20} style={{ borderRadius:"20px" }} >
                <CardHeader title="Confirm OTP" subheader="Enter the 6 digit code sent to mobile number +918xxxxxxxx5 and email id ssxxxxxxx@gmail.com" />
                  <CardContent>
                      <Grid container spacing={4}>
                          <Grid item xs={8}>
                              <Grid container spacing={2}>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField variant="outlined" fullWidth/>
                                  </Grid>
                              </Grid>

                          </Grid>
                      </Grid>
                    <Grid container spacing={4} style={{marginTop:6}}>
                      <Grid item xs={12}>
                        <Typography>Didn't get a code? <span><Typography color="primary">Resend Code </Typography></span>
                        </Typography>

                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" fullWidth size="large" color="primary" style={{borderRadius:50,height:"50px"}}>Send</Button>

                      </Grid>
                      <Grid item xs={12}>
                          <Typography variant="h6" >Remember your Password?</Typography>
                        <Typography variant="h4" color="primary" style={{paddingTop:10}}><img src={leftArrow} className="arrow-s" />  Sign in 
                          </Typography> 
                      
                      </Grid>
                      
                    </Grid>

                  </CardContent>
                  </Box>
            </Grid>
          </Grid>
        </Grid>
      
          
      </>
    );
  }
}
