export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgarrow = require("../assets/arrow.png");
export const bgImg = require("../assets/background-image.png");
export const img1 = require("../assets/background.png");
export const img2 = require("../assets/financial.png");
export const img3 = require("../assets/risk-management.png");
export const img4 = require("../assets/retirement.png");
export const img5 = require("../assets/income-tax.png");
export const img6 = require("../assets/budget-tracker.png");
export const img7 = require("../assets/finrobo-docs-vault.png");
export const img8 = require("../assets/mission-statement.png");
export const img9 = require("../assets/financial-planning.png");
export const facebook = require("../assets/facebook.png");
export const linkedin = require("../assets/linkedin.png");
export const instagram = require("../assets/instagram.png");
export const twitter = require("../assets/twitter.png")