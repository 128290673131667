import {
    Button,
    CardContent,
    Checkbox,
    TextField,
    Typography,
  } from "@material-ui/core";
  import React from "react";
  import { Grid, Card, CardHeader } from "@material-ui/core";
  import { leftArrow, bgImg } from "./assets";
  import "./Forgot.css";
  
  export default class ForgotPasswordWeb extends React.Component {
    render() {
      return (
        <>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <img src={bgImg} />
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={8} style={{ marginTop: 200 }}>
                <Card style={{ borderRadius: 10 }}>
                  <CardHeader
                    title="Forgot Password"
                    subheader="Don't worry! Enter your registered email or mobile to get password reset instructions"
                  />
                  <CardContent>
                    <Typography variant="h6">Email or Mobile Number</Typography>
                    <TextField variant="outlined" fullWidth />
                    <Grid container spacing={4} style={{ marginTop: 6 }}>
                      <Grid item xs={6}>
                        <Typography>Remember your password?</Typography>
                        <Typography
                          variant="h4"
                          color="primary"
                          style={{ paddingTop: 20 }}
                        >
                          <img src={leftArrow} className="arrow" /> Sign in
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ paddingTop: 30 }}>
                        <Button
                          variant="contained"
                          fullWidth
                          size="large"
                          color="primary"
                          style={{ borderRadius: 50, height: "50px" }}
                        >
                          Send
                        </Button>
                      </Grid>
                      <Grid item xs={5} />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
  }
  