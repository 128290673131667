// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import ContentManagementWeb from "../../blocks/ContentManagement/src/ContentManagementWeb.web";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import TeamBuilder from "../../blocks/TeamBuilder/src/TeamBuilder";
import VisualAnalytics from "../../blocks/VisualAnalytics/src/VisualAnalytics";
import Search from "../../blocks/Search/src/Search";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Customform from "../../blocks/customform/src/Customform";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import EmailAccountLoginBlockWeb from "../../blocks/email-account-login/src/EmailAccountLoginBlockWeb.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/EmailAccountRegistrationWeb.web";
import RecommendationEngine4 from "../../blocks/RecommendationEngine4/src/RecommendationEngine4";
import CfPortfolioInvestmentLogics3 from "../../blocks/CfPortfolioInvestmentLogics3/src/CfPortfolioInvestmentLogics3";
import PhoneVerification from "../../blocks/PhoneVerification/src/PhoneVerification";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPasswordWeb.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import ForgotPasswordOTPWeb from "../../blocks/forgot-password/src/ForgotPasswordOTPWeb.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import NewPasswordWeb from "../../blocks/forgot-password/src/NewPasswordWeb.web";
import Download from "../../blocks/Download/src/Download";
import OcropticalCharacterRecognition from "../../blocks/OcropticalCharacterRecognition/src/OcropticalCharacterRecognition";
import SaveAsPdf from "../../blocks/SaveAsPdf/src/SaveAsPdf";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import Notifications from "../../blocks/Notifications/src/Notifications";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import PremiumPlanComparison from "../../blocks/PremiumPlanComparison/src/PremiumPlanComparison";
import Analytics3 from "../../blocks/Analytics3/src/Analytics3";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Contactus from "../../blocks/contactus/src/Contactus";
import ContactusWeb from "../../blocks/contactus/src/ContactusWeb.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import TwilioIntegration2 from "../../blocks/TwilioIntegration2/src/TwilioIntegration2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import OTPInputAuthWeb from "../../blocks/otp-input-confirmation/src/OTPInputAuthWeb.web";
import Payments from "../../blocks/Payments/src/Payments";



const routeMap = {
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
ContentManagementWeb:{
  component:ContentManagementWeb,
 path:"/ContentManagementWeb"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
TeamBuilder:{
 component:TeamBuilder,
path:"/TeamBuilder"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Search:{
 component:Search,
path:"/Search"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Customform:{
 component:Customform,
path:"/Customform"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
EmailAccountLoginBlockWeb:{
  component:EmailAccountLoginBlockWeb,
 path:"/EmailAccountLoginBlockWeb"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountRegistrationWeb:{
  component:EmailAccountRegistrationWeb,
 path:"/EmailAccountRegistrationWeb"},
RecommendationEngine4:{
 component:RecommendationEngine4,
path:"/RecommendationEngine4"},
CfPortfolioInvestmentLogics3:{
 component:CfPortfolioInvestmentLogics3,
path:"/CfPortfolioInvestmentLogics3"},
PhoneVerification:{
 component:PhoneVerification,
path:"/PhoneVerification"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordWeb:{
  component:ForgotPasswordWeb,
 path:"/ForgotPasswordWeb"},
ForgotPasswordOTPWeb:{
 component:ForgotPasswordOTPWeb,
path:"/ForgotPasswordOTPWeb"},
NewPasswordWEb:{
 component:NewPasswordWeb,
path:"/NewPasswordWeb"},
Download:{
 component:Download,
path:"/Download"},
OcropticalCharacterRecognition:{
 component:OcropticalCharacterRecognition,
path:"/OcropticalCharacterRecognition"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
PremiumPlanComparison:{
 component:PremiumPlanComparison,
path:"/PremiumPlanComparison"},
Analytics3:{
 component:Analytics3,
path:"/Analytics3"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
ContactusWeb:{
  component:ContactusWeb,
 path:"/ContactusWeb"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
TwilioIntegration2:{
 component:TwilioIntegration2,
path:"/TwilioIntegration2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
OTPInputAuthWeb:{
  component:OTPInputAuthWeb,
 path:"/OTPInputAuthWeb"},
Payments:{
 component:Payments,
path:"/Payments"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;