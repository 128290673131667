import {
  Button,
  CardContent,
  Checkbox,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Grid, Box, CardHeader } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import { bgImg4, imgPasswordVisible, leftArrow } from "./assets";
import "./Forgot.css";


export default class NewPasswordWeb extends React.Component {
    demo() {
        return <img src={imgPasswordVisible} />;
      }

  render() {
    return (
      <>
        <Grid container direction="row" spacing={4} style={{ marginTop: 100 }}>
          <Grid item xs={6} >
              <img src={bgImg4} style={{marginTop:"-200px"}} />
            </Grid>  
          <Grid item xs={6}>
            <Grid item xs={8}>
              <Box boxShadow={20} className="mainbody">
                <CardHeader
                  className="new-card"
                  title="Reset Password"
                  subheader="Add new password and sign in to your account"
                />
                <CardContent>
                  {/* <Grid container spacing={4}>
                    <Grid item xs={8} />
                  </Grid> */}
                  <Grid container spacing={4} style={{ marginTop: 6 }}>
                    <Grid item xs={10}>
                      <Typography style={{marginLeft: "30px"}} > New Password </Typography>
                      <Input
                        id="outlined-full-width"
                        type="password"
                        // eslint-disable-next-line react-native/no-inline-styles
                        style={{
                          border: "0.2px solid gray",
                          paddingLeft: "20px",
                          marginLeft: "30px",
                          marginBottom: "10px",
                          borderRadius: "6px",
                          outline: "0px",
                        }}
                        fullWidth
                        endAdornment={this.demo()}
                      />
                        <Typography style={{marginLeft: "30px"}} > Confirm Password </Typography>
                      <Input
                        id="outlined-full-width"
                        type="password"
                        // eslint-disable-next-line react-native/no-inline-styles
                        style={{
                          border: "0.2px solid gray",
                          paddingLeft: "20px",
                          marginLeft: "30px",
                          borderRadius: "6px",
                          outline: "0px",
                        }}
                        fullWidth
                        endAdornment={this.demo()}
                      />
                    </Grid>
                    <Grid container item lg={12} md={12} xs={12}>
                     <Grid item lg={6} md={6} xs={12}></Grid>
                      <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        color="primary"
                        style={{ borderRadius: 50, height: "50px" }}
                      >
                        Send
                      </Button>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h6">
                        Remember your Password?
                      </Typography>
                      <Typography
                        variant="h4"
                        color="primary"
                        style={{ paddingTop: 10 }}
                      >
                        <img src={leftArrow} className="arrow-s" /> Sign in
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
