import React, { Component } from "react";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import {
  imgarrow,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  facebook,
  linkedin,
  instagram,
  twitter,
} from "./assets";
// import classes from './App.module.css'
import "./finance.css";

export default class ContentManagementWeb extends Component {
  render() {
    return (
      <>
        <div className="finance-page">
          {/*Starting image and content of financial planning */}
          <div className="background">
            <Grid container item justifyContent="center">
              <Grid item lg={5} md={5} sm={5}>
                <div className="financial-planning-summary">
                  <Typography
                    variant="h3"
                    className="financeplanningsummaryheading"
                  >
                    <b>Financial Planning</b>
                  </Typography>
                  <Grid>
                    <Typography className="financebody">
                      Financial planning is a methodical and systematic
                      <br />
                      approach to meet one's life goals. A financial plan
                      <br />
                      acts as aguide as you go through life's journey.
                      <br />
                      Essentially, it helps you be in control of your
                      <br />
                      income, expenses and investments in such a way
                      <br />
                      that you can manage your money and achieve
                      <br />
                      your goals.
                    </Typography>
                  </Grid>
                </div>
              </Grid>
              <Grid item lg={1} md={1} sm={1} />
              <Grid item lg={5} md={5} sm={5}>
                <img src={img1} alt="fin-back" className="background1" />
              </Grid>
            </Grid>
          </div>

          {/*Why finrobo */}
          <div className="why-finrobo">
            <Grid container item justifyContent="flex-start">
              <Grid item>
                <Typography variant="h3">
                  <b>Why Finrobo?</b>
                </Typography>
              </Grid>
              <Grid container item justifyContent="flex-start">
                <Grid item lg={4} md={4} sm={12}>
                  <Box boxShadow={20} className="why-finrobo-box">
                    <Grid item justifyContent="center">
                      <div className="content-boxes">
                        <img
                          className="images"
                          src={img2}
                          alt="financial-planning"
                        />
                        <div className="box-content">
                          <Typography variant="h6">
                            <b>Financial Planning</b>
                          </Typography>
                          <Typography>
                            People come to Finrobo for financial planning to
                            identify, set and achieve their financial goals
                          </Typography>
                        </div>
                        <img
                          className="arrow-image"
                          src={imgarrow}
                          alt="arrow"
                        />
                      </div>
                    </Grid>
                  </Box>
                </Grid>
                {/*<Grid item lg={1} md={1}></Grid>*/}
                <Grid item lg={4} md={4} sm={12}>
                  <Box boxShadow={20} className="why-finrobo-box">
                    <Grid item justifyContent="center">
                      <img
                        className="images"
                        src={img3}
                        alt="financial-planning"
                      />
                      <div className="box-content">
                        <Typography variant="h6">
                          <b>Risk Management</b>
                        </Typography>
                        <Typography>
                          Launch party pitch technology user experience
                          innovator buzz stealth MVP business model.
                        </Typography>
                      </div>
                      <img className="arrow-image" src={imgarrow} alt="arrow" />
                    </Grid>
                  </Box>
                </Grid>
                {/*<Grid item lg={1} md={1}></Grid>*/}
                <Grid item lg={4} md={4} sm={12}>
                  <Box boxShadow={20} className="why-finrobo-box">
                    <Grid item justifyContent="center">
                      <img
                        className="images"
                        src={img4}
                        alt="financial-planning"
                      />
                      <div className="box-content">
                        <Typography variant="h6">
                          <b>Retirement Planning</b>
                        </Typography>
                        <Typography>
                          Launch party pitch technology user experience
                          innovator buzz stealth MVP business model.
                        </Typography>
                      </div>
                      <img className="arrow-image" src={imgarrow} alt="arrow" />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <br />

              <Grid container item justifyContent="flex-start">
                <Grid item lg={4} md={4} sm={12}>
                  <Box boxShadow={20} className="why-finrobo-box">
                    <Grid item justifyContent="center">
                      <img
                        className="images"
                        src={img5}
                        alt="financial-planning"
                      />
                      <div className="box-content">
                        <Typography variant="h6">
                          <b>Online Income Tax</b>
                        </Typography>
                        <Typography>
                          Finrobo has the enabling tool for filling your income
                          tax returns online.Only for filling returns you update
                        </Typography>
                      </div>
                      <img className="arrow-image" src={imgarrow} alt="arrow" />
                    </Grid>
                  </Box>
                </Grid>
                {/*<Grid item lg={1} md={1}></Grid>*/}
                <Grid item lg={4} md={4} sm={12}>
                  <Box boxShadow={20} className="why-finrobo-box">
                    <Grid item justifyContent="center">
                      <img
                        className="images"
                        src={img6}
                        alt="financial-planning"
                      />
                      <div className="box-content">
                        <Typography variant="h6">
                          <b>Budget Tracker</b>
                        </Typography>
                        <Typography>
                          World class organizations effectively plan their
                          Annual and Monthly Budgeting exercise to reach their
                          financial goals
                        </Typography>
                      </div>
                      <img className="arrow-image" src={imgarrow} alt="arrow" />
                    </Grid>
                  </Box>
                </Grid>
                {/*<Grid item lg={1} md={1}></Grid>*/}
                <Grid item lg={4} md={4} sm={12}>
                  <Box boxShadow={20} className="why-finrobo-box">
                    <Grid item justifyContent="center">
                      <img
                        className="images"
                        src={img7}
                        alt="financial-planning"
                      />
                      <div className="box-content">
                        <Typography variant="h6">
                          <b>Finrobo Docs Vault</b>
                        </Typography>
                        <Typography>
                          Store and Access all your Personal Identity related
                          papers - PAN, Ration Card, Passport, Voter ID, Driving
                          License
                        </Typography>
                      </div>
                      <img className="arrow-image" src={imgarrow} alt="arrow" />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </div>

          {/*Mission statement */}
          <div className="mission-statement">
            <Grid container item justifyContent="flex-start">
              <Grid item lg={5} md={5} sm={12}>
                <div className="mission-stmt-summary">
                  <div className="heading">
                    <Typography variant="h5">
                      <b>Mission Statement</b>
                    </Typography>
                  </div>
                  <Typography>
                    To provide a system that helps organize all your financial
                    data from across various sources - Bankers, Investments
                    across asset classes, Liabilities, Annual Living Cost
                    details, Income across sources To provide a system that
                    helps organize all your financial data from across various
                    sources.
                  </Typography>
                </div>
                <Button variant="contained" color="primary" className="btn1">
                  Learn More
                </Button>
              </Grid>
              <Grid item lg={1} md={1} />
              <Grid item lg={5} md={5} sm={12}>
                <img className="image" src={img8} alt="mission-statement" />
              </Grid>
            </Grid>
          </div>

          {/*Financial Planning */}

          <div className="financial-planning">
            <Grid container item justifyContent="flex-start">
              <Grid item lg={5} md={5} sm={12}>
                <div className="finance-stmt-summary">
                  <div className="heading">
                    <Typography variant="h5">
                      <b>Financial Planning</b>
                    </Typography>
                  </div>
                  <Typography>
                    To provide a system that helps organize all your financial
                    data from across various sources - Bankers, Investments
                    acros asset classes, Liabilities, Annual Living Cost
                    details, Income across sources To provide a system that
                    helps organize all your financial data from across various
                    sources.
                  </Typography>
                </div>
                <Button variant="contained" color="primary" className="btn">
                  Learn More
                </Button>
              </Grid>
              <Grid item lg={1} md={1} />
              <Grid item lg={5} md={5} sm={12}>
                <img className="image" src={img9} alt="finance-planning" />
              </Grid>
            </Grid>
          </div>

          {/*About us */}

          <div className="about-us">
            <Grid container item justifyContent="flex-start">
              <Grid item lg={8} md={8} sm={12}>
                <div className="about-stmt-summary">
                  <div className="heading">
                    <Typography variant="h5">
                      <b>About us</b>
                    </Typography>
                  </div>
                  <Typography>
                    Sankar is a CFP from Financial Planning Standards Board of
                    India and a practicing financial planner and a Chartered
                    Financial Analyst having been in practice for the more than
                    12 years. Sankar has about 18 years of experience in the
                    financial services industry and has always been at both
                    planning and the delivery end of the business. Before moving
                    into the financial services business Sankar was associated
                    with Procter and Gamble for 20 years in sales management and
                    training management, training being one of his passions.
                    Sankar will be charge of overall business management and
                    will be heading the financial planning, advisory and
                    execution part of the business.
                  </Typography>
                </div>
              </Grid>
              <Grid item lg={1} md={1} />
              <Grid item lg={3} md={3}>
                <div className="heading">
                  <Typography variant="h6">
                    <b>Contact us</b>
                  </Typography>
                </div>
                <Typography>
                  <b>Address:</b>
                  <br />
                  <div className="address">
                    Venkatlakshmi Financial Advisory LLP,
                    <br />
                    VSankar, E3 The Shelter,
                    <br />
                    KK Pudur Road, Kovilmedu,
                    <br />
                    Coimbatore-641025
                    <br />
                    <br />
                    Email: sankar@allinall.in
                    <br />
                  </div>
                </Typography>
              </Grid>

              {/*Follow us */}
              <Grid container item justifyContent="flex-start">
                <Grid item>
                  <Typography variant="body2" className="follow">
                    Follow us
                  </Typography>
                </Grid>

                <Grid item>
                  <img src={facebook} alt="facebook" className="faceicon" />
                </Grid>

                <Grid item>
                  <img src={linkedin} alt="linkedin" className="icon" />
                </Grid>

                <Grid item>
                  <img src={instagram} alt="instagram" className="icon" />
                </Grid>

                <Grid item>
                  <img src={twitter} alt="twitter" className="icon" />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}


