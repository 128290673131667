import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CardContent,
  CardHeader
} from "@material-ui/core";
import React, { Component } from "react";
import "./EmailAccountRegistration.css";
import FinappHeader from "../../../components/src/FinappHeader.web";
import { imgPasswordVisible, bgimg, verifyIcon, signin_arrow, otpImg, leftArrow } from "./assets";
import Input from "@material-ui/core/Input";
import { Formik } from "formik";
import OTPInputAuthWeb from "../../otp-input-confirmation/src/OTPInputAuthWeb.web";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController";
import { imgPasswordInVisible } from "../../PremiumPlanComparison/src/assets";



export default class EmailAccountRegistrationWeb extends EmailAccountRegistrationController {
  


  render() {
    return (
      <>

        <FinappHeader />
        {/* <div className="container"> */}
        {!this.state.ShowOTP ?
          <>
            <img src={bgimg} className="bgimg-set" />
            <form id='regisform'>
              {/* <Formik></Formik> */}
              <div className="midbody">
                <Box boxShadow={20} className="mainbox">
                  <Grid container direction="column" spacing={3}>
                    <Typography variant="h4">Register yourself</Typography>
                    <Typography variant="subtitle1">
                      Sign up with your base information
                    </Typography>
                    <Grid container item>
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          style={{ paddingTop: "10px" }}
                        >
                          Saturation
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginLeft: "30px" }}>
                        <RadioGroup
                          row
                          aria-label="position"
                          //name="position"
                          name="salutation"
                          defaultValue="top"
                        >
                          <FormControlLabel
                            value="Mr"
                            control={<Radio color="primary" />}
                            label="Mr"
                          />
                          <FormControlLabel
                            value="Mrs"
                            control={<Radio color="primary" />}
                            label="Mrs"
                          />
                          <FormControlLabel
                            value="Ms"
                            control={<Radio color="primary" />}
                            label="Ms"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    {/* first Name And Email */}
                    <Grid container item justifyContent="flex-start">
                      <Grid item lg={5} md={5} sm={12}>
                        <Typography variant="subtitle1" >Full Name</Typography>
                        <TextField
                          id="outlined-full-width"
                          // label="Full Name"
                          // style={{ marginRight: "10px" }}
                          // placeholder="Placeholder"
                          // helperText="Full width!"
                          onInput={(e: any) => this.setState({ firstName: e.target.value })}
                          value={this.state.firstName}
                          fullWidth
                          name="user_name"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={1} md={1} />
                      <Grid item lg={6} md={6} sm={12}>
                        <Typography variant="subtitle1">Email Id</Typography>
                        <Grid container item direction="row">
                          <Grid item lg={10} md={10} sm={10}>
                            <TextField
                              name="email"
                              id="outlined-full-width"
                              onInput={(e: any) => this.setState({ email: e.target.value })}
                              fullWidth
                              value={this.state.email}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                            />
                          </Grid>

                          <Grid
                            item
                            lg={2}
                            md={2}
                            sm={2}
                            style={{ padding: "2px", marginTop: "25px" }}
                          >
                            <div
                              // eslint-disable-next-line react-native/no-inline-styles
                              onClick={this.verifyEmail}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "nowrap",
                                cursor: "pointer"
                              }}
                            >
                              <img
                                src={verifyIcon}
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  margin: "-5px 10px 0px 10px"
                                }}
                              />
                              <span>Verify</span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Email And Mobile Number */}
                    <Grid container item justifyContent="flex-start">
                      <Grid item lg={5} md={5} sm={12}>
                        <Typography variant="subtitle1">
                          Alternative email (optional)
                        </Typography>
                        <TextField
                          id="outlined-full-width"
                          onInput={(e: any) => this.setState({ alternate_email: e.target.value })}
                          value={this.state.alternate_email}
                          fullWidth
                          name="alternate_email"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={1} md={1} />
                      <Grid item lg={6} md={6} sm={12}>
                        <Typography variant="subtitle1">Mobile number</Typography>
                        <Grid container item direction="row">
                          <Grid item lg={10} md={10} sm={10}>
                            <TextField
                              name="phone_number"
                              onInput={(e: any) => this.setState({ phone_number: e.target.value })}
                              value={this.state.phone_number}
                              id="outlined-full-width"
                              fullWidth
                              InputLabelProps={{
                                shrink: true
                              }}
                              variant="outlined"
                            />
                          </Grid>

                          <Grid
                            item
                            lg={2}
                            md={2}
                            sm={2}
                            style={{ padding: "2px", marginTop: "25px" }}
                          >
                            <div
                              // eslint-disable-next-line react-native/no-inline-styles
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "nowrap"
                              }}
                            >
                              <img
                                src={verifyIcon}
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  margin: "-5px 10px 0px 10px"
                                }}
                              />
                              <span>Verify</span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Mobile Number and BirthDay */}
                    <Grid container item justifyContent="flex-start">
                      <Grid item lg={5} md={5} sm={12}>
                        <Typography variant="subtitle1">
                          Alternative Mobile Number (optional)
                        </Typography>
                        <TextField
                          id="outlined-full-width"
                          name="alternate_mobile_number"
                          onInput={(e: any) => this.setState({ alternate_mobile_number: e.target.value })}
                              value={this.state.alternate_mobile_number}
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={1} md={1} />
                      <Grid item lg={5} md={5} sm={12}>
                        <Typography variant="subtitle1">Date Of Birth</Typography>
                        <TextField
                          id="outlined-full-width"
                          onInput={(e: any) => this.setState({ date_of_bith: e.target.value })}
                          value={this.state.date_of_bith}
                          fullWidth
                          name="date_of_bith"
                          type="date"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    {/* password  */}
                    <Grid item lg={10} md={10}>
                      <Typography variant="subtitle1">Create Password</Typography>
                      {/* <TextField
                  id="outlined-full-width"
                  fullWidth
                  variant="outlined"
                /> */}
                      <Input
                        id="outlined-full-width"
                        name="password"
                        type={this.state.values?"password":"text"}
                        // eslint-disable-next-line react-native/no-inline-styles
                        style={{
                          border: "0.2px solid gray",
                          padding: "10px",
                          borderRadius: "4px",
                          outline: "0px"
                        }}
                        fullWidth
                        endAdornment={<img
                          src={this.state.values ? imgPasswordVisible : imgPasswordInVisible}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.setState({ values: !this.state.values })}
                        />}
                      />
                    </Grid>
                    {/* password */}
                    <Grid item lg={10} md={10}>
                      <Typography variant="subtitle1">Confirm Password</Typography>
                      {/* <TextField
                  id="outlined-full-width"
                  fullWidth
                  variant="outlined"
                /> */}
                      <Input
                        id="outlined-full-width"
                        type={this.state.values?"password":"text"}
                        name="confirm_password"
                        style={{
                          border: "0.2px solid gray",
                          padding: "10px",
                          borderRadius: "4px",
                          outline: "0px",
                        }}
                        fullWidth
                        endAdornment={<img
                          src={this.state.values ? imgPasswordVisible : imgPasswordInVisible}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.setState({ values: !this.state.values })}
                        />}
                      />
                    </Grid>
                    {/* Are Your Married and dependents */}
                    <Grid
                      container
                      item
                      justifyContent="flex-start"
                      direction="row"
                      style={{ marginTop: "30px" }}
                    >
                      <Grid item lg={2}>
                        <Typography
                          variant="subtitle1"
                          style={{ paddingTop: "10px" }}
                        >
                          Are you married?
                        </Typography>
                      </Grid>
                      <Grid item lg={3}>
                        <RadioGroup
                          row
                          aria-label="position"
                          //name="position"
                          defaultValue="top"
                          name="married"
                        >
                          <FormControlLabel
                            value="No"
                            control={<Radio color="primary" />}
                            label="No"
                          />
                          <FormControlLabel
                            value="Yes"
                            control={<Radio color="primary" />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item lg={2}>
                        <Typography
                          variant="subtitle1"
                          // eslint-disable-next-line react-native/no-inline-styles
                          style={{
                            whiteSpace: "nowrap",
                            paddingTop: "10px",
                            marginLeft: "20px",
                          }}
                        >
                          Having Dependents?
                        </Typography>
                      </Grid>
                      <Grid item lg={1} />
                      <Grid item lg={3}>
                        <RadioGroup
                          row
                          aria-label="position"
                          //name="position"
                          name="dependants"
                          defaultValue="top"
                        >
                          <FormControlLabel
                            value="No"
                            control={<Radio color="primary" />}
                            label="No"
                          />
                          <FormControlLabel
                            value="Yes"
                            control={<Radio color="primary" />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    {/* are Your Parents */}
                    <Grid
                      container
                      item
                      direction="column"
                      style={{ marginTop: "30px" }}
                    >
                      <Grid item>
                        <Typography variant="subtitle1">
                          Are your parents alive today?
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          aria-label="position"
                          //name="position"
                          // onInput={(e: any) => this.setState({ parents_alive: e.target.value })}
                          // value={this.state.parents_alive}
                          name="parents_alive"
                          defaultValue="top"
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td style={{ padding: "10px" }}>
                                  <FormControlLabel
                                    value="Both Alive"
                                    control={<Radio color="primary" />}
                                    label="Both Alive"
                                  />
                                </td>
                                <td style={{ padding: "10px" }}>
                                  <FormControlLabel
                                    value="Father Only"
                                    control={<Radio color="primary" />}
                                    label="Father Only"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "10px" }}>
                                  <FormControlLabel
                                    value="Mother Only"
                                    control={<Radio color="primary" />}
                                    label="Mother Only"
                                  />
                                </td>
                                <td style={{ padding: "10px" }}>
                                  <FormControlLabel
                                    value="Both not alive"
                                    control={<Radio color="primary" />}
                                    label="Both not alive"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    {/* read agree to the terms */}
                    <Grid item>
                      <FormControlLabel
                        control={<Checkbox name="checkedC" />}
                        label="I have read and a agree to the terms of conditions and privacy policy"
                      />
                    </Grid>
                    {/* submit Btn */}
                    <Grid item>
                      <Button className="btn1" onClick={this.rs}>Sign in</Button>
                    </Grid>
                    {/* hav a account */}
                    <Grid item style={{ marginTop: "30px" }}>
                      <Typography variant="subtitle1">
                        Already have an account?
                      </Typography>
                    </Grid>
                    {/* sign In btn */}
                    <Grid item>
                      <a
                        href=""
                        // eslint-disable-next-line react-native/no-inline-styles
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          // fontWeight: "700",
                          fontSize: "20px",
                          marginTop: "20px"
                        }}
                      >
                        <img
                          src={signin_arrow}
                          style={{ height: "15px", width: "25px" }}
                        />
                        <span style={{ marginLeft: "10px" }} >Sign In</span>
                      </a>
                      {/* <Button onClick={this.fetchData}>submit</Button> */}
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </form>
          </>
          : <div style={{ position: "fixed" }}>
            <Grid container spacing={4} >

              <Grid item xs={6}>
                <img src={otpImg} />
              </Grid>
              <Grid item xs={6}>

                <Grid item xs={8} style={{ marginTop: 300 }}>
                  <Box boxShadow={20} style={{ borderRadius: "20px", padding: "30px" }}>
                    <CardHeader title="Confirm OTP" subheader={`Enter the 6 digit code sent to email id ${this.state.email}`} />
                    <CardContent>
                      <Grid container spacing={4}>
                        <Grid item xs={8}>
                          <Grid container spacing={2}>
                            <Grid item xs={2}>
                              <TextField variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                              <TextField variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                              <TextField variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                              <TextField variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                              <TextField variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                              <TextField variant="outlined" fullWidth />
                            </Grid>
                          </Grid>

                        </Grid>
                      </Grid>
                      <Grid container spacing={4} style={{ marginTop: 6 }}>
                        <Grid item xs={12}>
                          <div style={{ display: "flex" }}>
                            <Typography>Didn't get a code? </Typography>
                            <Typography color="primary">Resend Code
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <Button variant="contained" fullWidth size="large" color="primary"
                            onClick={() => this.setState({ ShowOTP: false })}
                            style={{ borderRadius: 50, height: "50px" }}>
                            Confirm
                            </Button>

                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" >Already have an account?</Typography>
                          <Typography variant="h4" color="primary" style={{ paddingTop: 10 }}>
                            <img src={leftArrow} className="arrow-s" />  Sign in
                          </Typography>

                        </Grid>

                      </Grid>

                    </CardContent>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </div>
        }
        {/* </div> */}
      </>
    );
  }
}
