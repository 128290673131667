import React, { Component } from "react";
import { TextField, Button } from "@material-ui/core";
import "./Contactus.css";

class ContactusWeb extends Component {
  render() {
    const handleOnChange = (value: any) => {
      this.setState({
        phone: value
      });
    };

    return (
      <div className="contact-contanier">
        <h1 className="heading">Contact Us</h1>

        <div className="container">
          <div className="content-details">
            <div className="first">
              <label className="subtopics">Name</label>
              <TextField
                type="text"
                variant="outlined"
                size="small"
                style={{ marginTop: 10, width: 300 }}
              />
              <br />
              <label className="subtopics">Mail id</label>
              <TextField
                type="email"
                variant="outlined"
                size="small"
                style={{ marginTop: 10, width: 300 }}
              />
              <br />
              <label
                className="subtopics"
                style={{ marginBottom: 10, width: 300 }}
              >
                Mobile Number
              </label>
              <TextField
                type="tel"
                variant="outlined"
                size="small"
                placeholder="+919876543210"
                style={{ marginTop: 10, width: 300 }}
              />
              <br />
              <label className="subtopics">Your residential address</label>
              <TextField
                type="text"
                variant="outlined"
                multiline
                rows={5}
                style={{ marginTop: 10, width: 300 }}
              />
              <br />
            </div>
            <div className="second">
              <label className="subtopics">Your office address</label>
              <TextField
                type="text"
                variant="outlined"
                multiline
                rows={5}
                style={{ marginTop: 10, marginRight: 20 }}
              />
              <br />
              <label className="subtopics">You may add your comments</label>
              <TextField
                type="text"
                variant="outlined"
                multiline
                rows={5}
                style={{ marginTop: 10, marginRight: 20 }}
              />
              <br />
              <div className="button">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    borderRadius: 20,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
          <div className="company_address">
            <h1 className="location-heading">Location</h1>
            <p className="location-subs">
              <b>Venkatlakshmi Financial Advisory LLP,</b>
            </p>
            <p className="location-subs">VSankar, E3 The Shelter,</p>
            <p className="location-subs">KK Pudur Road, Kovilmedu</p>
            <p className="location-subs">Coimbatore-641025</p>
            <p className="location-subs">E-mail:sankar@allinall.in</p>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactusWeb;
