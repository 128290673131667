import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import Fab from "@material-ui/core/Fab";
// import Box from "@material-ui/core/Box";
import { NavLink } from "react-router-dom";
import "./FinappHeader.css";
import { logo } from "./assets";

class FinappHeader extends Component {
  render() {
    return (
      <div>
        <AppBar
          position="static"
          style={{
            backgroundColor: "white",
            color: "black",
            boxShadow: "0px 0px 0px 0px",
          }}
        >
          <Toolbar>
            <img
              src={logo}
              alt="logo"
              style={{
                width: "180px",
                height: "50px",
                marginLeft: "82px",
                padding: "15px",
              }}
            />
            <NavLink
              exact
              activeClassName="active_class"
              className="headerHome"
              to="/home"
            >
             <b>Home</b> 
            </NavLink>
            <NavLink
              exact
              activeClassName="active_class"
              className="header"
              to="/aboutus"
            >
             <b>About Us</b> 
            </NavLink>
            <NavLink
              exact
              activeClassName="active_class"
              className="header"
              to="/services"
            >
              <b>Services</b>
            </NavLink>
            <NavLink
              exact
              activeClassName="active_class"
              className="header"
              to="/photo"
            >
              <b>Photos</b>
            </NavLink>
            <NavLink
              exact
              activeClassName="active_class"
              className="header"
              to="/contacus"
            >
              <b>Contact us</b>
            </NavLink>
            <NavLink
              exact
              activeClassName="active_class"
              className="header"
              to="/blog"
            >
              <b>Blog</b>
            </NavLink>
            <Typography style={{ color: "#9097BB", padding: "20px", textAlign: "justify" }}>
              don't have an account?
            </Typography>
            <Button
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                border: "2px solid blue",
                padding: "7px 15px 7px 15px",
                color: "blue",
                borderRadius: "20px",
                fontSize: "15px"
              }}
            >
              Get Started
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
export default FinappHeader;
